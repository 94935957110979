.horizontal {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  height: 33%;
}

.vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  justify-content: center;
  align-self: center;
  position: relative;
}

.horizontal .left {
  position: absolute;
  width: 60%;
}

.horizontal .left > div {
  margin-left: -30px;
}

.horizontal .right {
  position: absolute;
  width: 60%;
  right: 0;
  overflow: hidden;
}

.horizontal .right > div {
  margin-right: -30px;
}

.name {
  position: absolute;
  top: 15px;
  width: 100%;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  color: #6d6d6d;
}

@media only screen and (max-height: 500px) {
  .horizontal .left,
  .horizontal .right {
    max-width: 210px;
  }
}

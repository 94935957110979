html,
body,
#root {
  overscroll-behavior: contain;
}

body {
  margin: 0;
  font-family: "Helvetica", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.error {
  color: #f55c47;
}

.notification-success {
  background-color: #19665a !important;
}

.notification-error {
  background-color: #f55c47 !important;
}

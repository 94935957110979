.main {
  background: linear-gradient(180deg, #4caa9c 0%, #19665a 100%);
  display: flex;
  flex-direction: column;
}

.internet-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  background-color: #f55c47;
  color: #fff;
  font-size: x-small;
}

.main-content {
  display: flex;
  flex: 1;
  padding: 20px 30px;
}

.left {
  display: flex;
  flex: 1;
}

.settings {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.right {
  display: flex;
  align-items: center;
}

.logo {
  height: 36px;
  padding-right: 10px;
}

.text {
  flex: 1;
}

.greeting {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ceebd7;
}

.status-message {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ceebd7;
  display: flex;
}

.status {
  color: #fff;
}

.button {
  height: 25px;
}

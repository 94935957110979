.main {
  position: absolute;
  inset: 0;
  z-index: 100;
  background-color: #000;
  opacity: 0.5;
}

.action {
  margin: -100px auto 42px auto;
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: #fff;
}

.action > div {
  margin: 0 10px;
  padding: 6px 15px;
  border-radius: 50%;
  width: 58px;
  height: 58px;
}

.cancel {
  background-color: #333;
}

.ok {
  background-color: #4caa9c;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: #fff;
}

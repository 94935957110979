.shell {
  background: linear-gradient(180deg, #4caa9c 0%, #19665a 100%);
  position: absolute;
  inset: 0;
  padding: 20px 60px 40px 60px;
  overflow: auto;
}

.flex {
  display: flex;
}

.login {
  flex: 1;
  align-self: center;
}

.logo {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  margin-bottom: 40px;
}

.logo > img {
  width: 55px;
  margin-bottom: 6px;
}

.header {
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}

.forgot {
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #ceebd7;
  margin: 18px 16px 35px 16px;
}

.loginButton {
  display: flex;
  flex: 1;
  background-color: #4caa9c;
  box-shadow: -6px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 17px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: #fff;
  height: 50px;
}

.loginProcess {
  background-color: #888;
}

.loginButton > svg {
  font-size: 25px;
}

.signupline {
  margin-top: 40px;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #fff;
}

.signup {
  margin-top: 5px;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-decoration-line: underline;
}

.error {
  color: #f55c47;
}

.floating > input {
  font-weight: bold;
}

.floating > input ~ label {
  opacity: 0.8 !important;
}

.floating > input:focus {
  box-shadow: none;
  border: 1px solid #4caa9c;
}

.mobile {
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
}

.number {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.full {
  font-family: "Helvetica";
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  max-width: 420px;
  max-height: 830px;
  margin: 0 auto;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icon {
  height: 20px;
  padding-right: 5px;
}

.statusBar {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.signal {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
}

.text {
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
}

.stale {
  border: 1px solid #f55c47;
}

.main {
  background: #e5e5e5;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 420px;
}

.footer {
  display: flex;
  padding: 20px;
}

.scheduleButton {
  display: flex;
  height: 50px;
  width: 65px;
  box-shadow: -6px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 17px;
  align-items: center;
  justify-content: center;
}

.onOffButton {
  display: flex;
  flex: 1;
  background-color: #4caa9c;
  box-shadow: -6px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 17px;
  margin-left: 35px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}

.onOffButton img {
  height: 25px;
}

.motorOff {
  background-color: #f55c47;
}

.motorUpdating {
  background-color: #888;
}

.schedule {
  padding: 30px;
  border-top: 2px solid #4caa9c;
}

.scheduleTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

.slider {
  margin: 40px 40px 25px 40px;
  background-color: #ececec;
  border-radius: 18px;
  height: 10px;
}

.thumb {
  height: 40px;
  width: 40px;
  margin: -15px 0;
  border-radius: 50%;
  background: linear-gradient(180deg, #4caa9c 0%, #19665a 100%);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  outline-width: 0;
}

.thumbOff {
  background: linear-gradient(180deg, #c4c4c4 0%, #6d6d6d 100%);
}

.scheduleLabels {
  margin: 0 20px 0 50px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
}

.greyHighlight {
  color: #c4c4c4;
}

.colorHighlight {
  color: #19665a;
}

.scheduleTime {
  display: flex;
  justify-content: space-between;
  margin: 20px 20px;
}

.timer {
  padding: 10px 10px 5px 10px;
  border: 1px solid #4caa9c;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 17px;
}

.timerLabel {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #19665a;
}

.timer input {
  /* margin-top: 5px; */
  border: 0;
}

.full {
  font-family: "Helvetica";
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  max-width: 420px;
  max-height: 830px;
  margin: 0 auto;
}

.body {
  background: #e5e5e5;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
}

.floating > input,
.floating > textarea {
  font-weight: bold;
}

.floating > input ~ label,
.floating > textarea ~ label {
  opacity: 0.8 !important;
}

.floating > input:focus,
.floating > textarea:focus {
  box-shadow: none;
  border: 1px solid #4caa9c;
}

.floating > textarea {
  height: 100px !important;
}

/* .floating > input,
.floating > textarea {
  padding: 10px 12px !important;
  height: 48px !important;
  font-weight: bold;
  font-size: 14px;
}

.floating > textarea {
  height: 58px !important;
}

.floating > input ~ label,
.floating > textarea ~ label {
  padding: 12px 8px !important;
}

.floating > textarea:focus,
.floating > input:focus {
  box-shadow: none;
  border: 1px solid #4caa9c;
}

.floating > input:focus,
.floating > input:not(:placeholder-shown) {
  padding: 14px 12px 5px 13px !important;
}

.floating > textarea:focus,
.floating > textarea:not(:placeholder-shown) {
  padding: 18px 12px 5px 13px !important;
}

.floating > input:focus ~ label,
.floating > textarea:focus ~ label,
.floating > input:not(:placeholder-shown) ~ label,
.floating > textarea:not(:placeholder-shown) ~ label {
  opacity: 0.8 !important;
  font-weight: 300;
  font-size: 12px;
  padding: 14px 8px !important;
} */

[class="row"] {
  margin-bottom: 20px;
}

[class="col-4"] {
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
}

[class="col-8"] {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.footer {
  padding: 20px;
}

.editButton {
  display: flex;
  flex: 1;
  background-color: #4caa9c;
  box-shadow: -6px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 17px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: #fff;
  height: 50px;
}

.editButton img {
  height: 20px;
}

.logoutButton {
  display: flex;
  flex: 1;
  color: #4caa9c;
  border: 1px solid #4caa9c;
  box-shadow: -6px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 17px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  height: 50px;
  margin-top: 10px;
}

.logoutIcon {
  font-size: 25px;
}

.editIcon {
  font-size: 18px;
}
